var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    {
      attrs: {
        value: _vm.value,
        disabled: _vm.disabled,
        "filter-option": _vm.useLocalFilter,
        "option-filter-prop": "children",
        "allow-clear": "",
        "show-search": ""
      },
      on: { change: _vm.onChange }
    },
    _vm._l(_vm.options, function(opt) {
      return _c(
        "a-select-option",
        { key: opt.key, attrs: { value: opt.value } },
        [
          _c("a-tooltip", [
            _c("span", { attrs: { slot: "title" }, slot: "title" }, [
              _vm._v(" " + _vm._s(opt.label) + " ")
            ]),
            _vm._v(" " + _vm._s(opt.label) + " ")
          ])
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }