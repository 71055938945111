


























import { debounceProcess } from "@/helpers/debounce";
import { useProductUom } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { ProductUomResponseDto } from "@/models/interface/master-uom";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectProductUom extends Vue {
  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  options: Array<Option<ProductUomResponseDto>> = [];
  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    this.fetchOptions();
  }

  fetchOptions(params: RequestQueryParams = new RequestQueryParams()): void {
    const { findAll, toOptions } = useProductUom();
    this.loading = true;
    findAll(params)
      .then(({ data }) => {
        this.options = toOptions(data);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const { searchBy } = useProductUom();
    const params = new RequestQueryParams();
    params.search = searchBy({ name: val });

    this.fetchOptions(params);
  }

  findOption(value: string): Option<ProductUomResponseDto> | undefined {
    return this.options.find(e => value === e.value);
  }
}
