var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset",
    [
      _c("legend", [_vm._v(" " + _vm._s(_vm.$t("lbl_supplier")) + " ")]),
      _c("a-table", {
        attrs: {
          "data-source": _vm.form.productSuppliers,
          columns: _vm.columns,
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange
          },
          pagination: {
            showSizeChanger: true,
            showTotal: function(total) {
              return _vm.$t("lbl_total_items", { total: total })
            }
          },
          size: "small",
          scroll: { y: 700 }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c(
                  "a-button-group",
                  [
                    _c("a-button", {
                      attrs: { icon: "plus", type: "primary" },
                      on: { click: _vm.addRow }
                    }),
                    _c("a-button", {
                      attrs: { icon: "delete", type: "danger" },
                      on: { click: _vm.handleDeleteRow }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "nullable",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
            }
          },
          {
            key: "supplier",
            fn: function(text, row) {
              return [
                _c("a-select", {
                  staticClass: "w-100",
                  attrs: {
                    "allow-clear": "",
                    "show-search": "",
                    "filter-option": false,
                    placeholder: _vm.$t("common.select-text", {
                      text: _vm.$t("lbl_supplier")
                    }),
                    "dropdown-match-select-width": false,
                    "label-in-value": "",
                    loading: row.loadingSupplier,
                    options: row.isSearchSupplier
                      ? row.supplierOptions
                      : _vm.supplierOptions
                  },
                  on: {
                    search: function($event) {
                      return _vm.onSearchSupplier(row, $event)
                    },
                    change: function($event) {
                      return _vm.onChangeSupplier(row, $event)
                    }
                  },
                  model: {
                    value: row.supplier,
                    callback: function($$v) {
                      _vm.$set(row, "supplier", $$v)
                    },
                    expression: "row.supplier"
                  }
                })
              ]
            }
          },
          {
            key: "address",
            fn: function(text, row) {
              return [
                _c("SelectShipAddress", {
                  staticClass: "w-100",
                  attrs: { "supplier-code": row.id },
                  model: {
                    value: row.address,
                    callback: function($$v) {
                      _vm.$set(row, "address", $$v)
                    },
                    expression: "row.address"
                  }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }