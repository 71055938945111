var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset",
    [
      _c("legend", [_vm._v(" " + _vm._s(_vm.$t("lbl_uom_conversion")) + " ")]),
      _c("a-table", {
        attrs: {
          "data-source": _vm.form.uomConversions,
          columns: _vm.columns,
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange
          },
          size: "small",
          scroll: { y: 700 }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c(
                  "a-button-group",
                  [
                    _c("a-button", {
                      attrs: { icon: "plus", type: "primary" },
                      on: { click: _vm.addRow }
                    }),
                    _c("a-button", {
                      attrs: { icon: "delete", type: "danger" },
                      on: { click: _vm.handleDeleteRow }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "currency",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm._f("currency")(text)))])
            }
          },
          {
            key: "conversionRate",
            fn: function(text, row) {
              return [
                _c("a-input-number", {
                  staticClass: "w-100",
                  attrs: {
                    formatter: _vm.formatterNumber,
                    parser: _vm.reverseFormatNumber,
                    precision: _vm.storeBaseDecimalPlace,
                    min: 0
                  },
                  model: {
                    value: row.conversionRate,
                    callback: function($$v) {
                      _vm.$set(row, "conversionRate", _vm._n($$v))
                    },
                    expression: "row.conversionRate"
                  }
                })
              ]
            }
          },
          {
            key: "uomSource",
            fn: function(text, row) {
              return [
                _c("SelectProductUom", {
                  staticClass: "w-100",
                  on: {
                    "update:meta": function($event) {
                      return _vm.onChangeUom($event, row, "uomSource")
                    }
                  },
                  model: {
                    value: row.uomSource,
                    callback: function($$v) {
                      _vm.$set(row, "uomSource", $$v)
                    },
                    expression: "row.uomSource"
                  }
                })
              ]
            }
          },
          {
            key: "uomConversion",
            fn: function(text, row) {
              return [
                _c("SelectProductUom", {
                  staticClass: "w-100",
                  on: {
                    "update:meta": function($event) {
                      return _vm.onChangeUom($event, row, "uomConversion")
                    }
                  },
                  model: {
                    value: row.uomConversion,
                    callback: function($$v) {
                      _vm.$set(row, "uomConversion", $$v)
                    },
                    expression: "row.uomConversion"
                  }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }