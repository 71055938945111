




























































import { debounce } from "@/helpers/debounce";
import { useContactData } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  ProductSupplierRow,
  State as ProductState,
} from "@/store/product.store";
import { LabelInValue } from "@/types";
import { AddressDataDto, ListContactDataDto } from "@interface/contact-data";
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import SelectShipAddress from "./SelectShipAddress.vue";

@Component({
  components: {
    SelectShipAddress,
  },
  computed: {
    ...mapGetters({
      form: "productStore/getForm",
    }),
  },
  methods: {
    ...mapActions({
      addRow: "productStore/addProductSupplier",
      deleteRow: "productStore/deleteProductSupplier",
    }),
  },
})
export default class FormProductSupplier extends Vue {
  form!: ProductState["form"];
  addRow!: () => void;
  deleteRow!: (payload: Array<number>) => void;

  selectedRowKeys: Array<number> = [];
  supplierOptions: Option<ListContactDataDto>[] = [];

  columns = [
    {
      title: this.$t("lbl_supplier_name"),
      dataIndex: "name",
      scopedSlots: { customRender: "supplier" },
    },
    {
      title: this.$t("lbl_supplier_code"),
      dataIndex: "supplier.key",
      width: "150px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_address"),
      dataIndex: "address",
      scopedSlots: { customRender: "address" },
    },
  ];

  mounted() {
    this.getSupplierList();
  }

  onChangeSupplier(
    row: ProductSupplierRow,
    value: LabelInValue | undefined
  ): void {
    const options: Option<ListContactDataDto>[] = row.isSearchSupplier
      ? row.supplierOptions
      : this.supplierOptions;
    const opt = options.find(item => item.key === value?.key);
    row.supplierFirstName = opt?.meta?.firstName ?? "";
    row.address = "";
    row.cityDistrict = "";
    row.partNumber = "";

    if (
      opt &&
      opt.meta &&
      opt.meta.addressDataList &&
      opt.meta.addressDataList.length > 0
    ) {
      const address: AddressDataDto | undefined = opt.meta.addressDataList.find(
        item => item.primaryShipTo && item.shipTo
      );
      row.address = address?.address || "";
      row.cityDistrict = address?.cityDistrict || "";
    }
  }

  onSelectChange(keys: Array<number>): void {
    this.selectedRowKeys = keys;
  }

  handleDeleteRow(): void {
    this.deleteRow(this.selectedRowKeys);
    this.selectedRowKeys = [];
  }

  async fetchSupplierList(
    params: RequestQueryParamsModel = new RequestQueryParams()
  ): Promise<Option<ListContactDataDto>[]> {
    const { findSuppliers, toSupplierOptions } = useContactData();
    const res = await findSuppliers(params);
    return toSupplierOptions(res.data, "supplierNumber");
  }

  async getSupplierList(): Promise<void> {
    this.supplierOptions = await this.fetchSupplierList();
  }

  onSearchSupplier(row: ProductSupplierRow, search?: string): void {
    row.isSearchSupplier = true;
    const { filterBy } = useContactData();
    const params = new RequestQueryParams();
    if (search) {
      params.search = filterBy({ firstName: search, lastName: search });
    }
    debounce(async () => {
      row.loadingSupplier = true;
      row.supplierOptions = await this.fetchSupplierList(params);
      row.loadingSupplier = false;
    }, 500);
  }
}
