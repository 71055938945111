import { render, staticRenderFns } from "./FormBasicInfo.vue?vue&type=template&id=48f87858&scoped=true&"
import script from "./FormBasicInfo.vue?vue&type=script&lang=ts&"
export * from "./FormBasicInfo.vue?vue&type=script&lang=ts&"
import style0 from "./FormBasicInfo.vue?vue&type=style&index=0&id=48f87858&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48f87858",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/workspace/SPP/spp-erp-fe-development/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('48f87858')) {
      api.createRecord('48f87858', component.options)
    } else {
      api.reload('48f87858', component.options)
    }
    module.hot.accept("./FormBasicInfo.vue?vue&type=template&id=48f87858&scoped=true&", function () {
      api.rerender('48f87858', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Product/FormBasicInfo.vue"
export default component.exports