






























import {
  FormBasicInfo,
  FormProductAccount,
  FormProductSupplier,
  FormUomConversion,
} from "@/components/Product";
import { useProduct } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Mode } from "@/models/enums/global.enum";
import {
  ProductCreateRequestDto,
  ProductResponseDto,
  ProductUpdateRequestDto,
} from "@/models/interface/master-product";
import { State as ProductState } from "@/store/product.store";
import { FormModel } from "ant-design-vue";
import { Component, Mixins, Prop, Ref } from "vue-property-decorator";
import { mapActions, mapMutations, mapState } from "vuex";

@Component({
  components: {
    FormBasicInfo,
    FormProductSupplier,
    FormProductAccount,
    FormUomConversion,
  },
  beforeRouteLeave(_to, _from, next) {
    this.resetStore();
    next();
  },
  computed: {
    ...mapState({
      store: (st: any) => st.productStore as ProductState,
    }),
  },
  methods: {
    ...mapActions({
      resetStore: "productStore/resetStore",
      getDetail: "productStore/getDetailProduct",
    }),
    ...mapMutations({
      setForm: "productStore/setForm",
    }),
  },
})
export default class FormPage extends Mixins(MNotificationVue) {
  @Ref("formBasic") formBasic!: any;
  @Ref("formAccount") formAccount!: any;
  @Prop({ type: String, required: false, default: "" }) id!: string; // product id

  store!: ProductState;
  resetStore!: () => void;
  getDetail!: (productId: string) => void;

  loading = false;

  mounted(): void {
    if (this.isEditMode && this.id) {
      this.getDetail(this.id);
    }
  }

  get isCreateMode(): boolean {
    return this.$route.meta?.mode === Mode.CREATE;
  }

  get isEditMode(): boolean {
    return this.$route.meta?.mode === Mode.EDIT;
  }

  get titlePage(): string {
    return this.isEditMode
      ? this.$t("lbl_edit_x", { x: this.$t("lbl_part") }).toString()
      : this.$t("lbl_create_part").toString();
  }

  async handleSave(): Promise<void> {
    const formBasic: FormModel = this.formBasic.form;
    const formAccount: FormModel = this.formAccount.formAccount;
    try {
      await formBasic.validate();
      await formAccount.validate();

      if (this.isEditMode && this.id) {
        this.handleUpdate(this.id);
      } else if (this.isCreateMode) {
        this.handleCreate();
      }
    } catch (error) {
      this.showNotifWarning("notif_validation_error");
    }
  }

  async handleCreate(): Promise<void> {
    const { create, mapFormToCreateDto } = useProduct();
    try {
      this.loading = true;
      const req: ProductCreateRequestDto = mapFormToCreateDto(this.store.form);
      await create(req);
      this.showNotifSuccess("notif_create_success", {
        documentNumber: req.name,
      });
      this.handleBack();
    } catch (error) {
      this.showNotifError("notif_create_fail");
    } finally {
      this.loading = false;
    }
  }

  async handleUpdate(productId: string): Promise<void> {
    const { update, mapFormToUpdateDto } = useProduct();
    try {
      this.loading = true;
      const req: ProductUpdateRequestDto = mapFormToUpdateDto(this.store.form);
      const res: ProductResponseDto = await update(productId, req);
      this.showNotifSuccess("notif_update_success", {
        documentNumber: res.name,
      });
      this.handleBack();
    } catch (error) {
      this.showNotifError("notif_update_fail");
    } finally {
      this.loading = false;
    }
  }

  handleBack(): void {
    this.$router.push({
      name: "logistic.part",
      query: { ...this.$route.query },
    });
  }
}
