





















import { SearchBuilder } from "@/builder";
import { useContactData, useLocalFilter } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class SelectShipAddress extends Vue {
  useLocalFilter = useLocalFilter;

  @Prop({ type: String, required: true })
  value!: string;

  @Prop({ type: String, required: true })
  supplierCode!: string;

  @Prop({ type: Boolean, required: false, default: false })
  disabled!: boolean;

  options: Array<Option> = [];
  loading = false;

  @Watch("supplierCode")
  onChangeSupplierCode(newValue: string | undefined): void {
    if (newValue) {
      this.fetchOptions();
    }
  }

  onChange(e: string | undefined): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }

  fetchOptions(): void {
    const { findSuppliers, toShipToAddressOptions } = useContactData();
    const builder = new SearchBuilder();
    const searchByCode = builder
      .push(["supplierNumber", this.supplierCode])
      .build();
    this.options = [];
    this.loading = true;
    findSuppliers({ search: searchByCode })
      .then(res => {
        const [contact] = res.data;
        if (!contact) return;
        this.options = toShipToAddressOptions(contact.addressDataList);
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
