













import {
  useLocalFilter,
  useMapMasterTypeToOptionAlt,
  useProduct,
} from "@/hooks";
import { Option } from "@/models/class/option.class";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectProductType extends Vue {
  useLocalFilter = useLocalFilter;

  @Prop({ required: true, type: String, default: "" })
  value!: string;

  options: Option[] = [];
  loading = false;

  created(): void {
    this.fetchData();
  }

  emits(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }

  fetchData(): void {
    const { findAllProductType } = useProduct();
    this.loading = true;
    findAllProductType()
      .then(res => {
        this.options = useMapMasterTypeToOptionAlt(res);
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
