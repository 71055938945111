





















































import SelectProductUom from "@/components/custom/select/SelectProductUom.vue";
import { Option } from "@/models/class/option.class";
import { ProductUomResponseDto } from "@/models/interface/master-uom";
import { FormValue, UomConversionRow } from "@/store/product.store";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapGetters, mapState } from "vuex";

@Component({
  components: {
    SelectProductUom,
  },
  computed: {
    ...mapState({
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
    ...mapGetters({
      form: "productStore/getForm",
    }),
  },
  methods: {
    ...mapActions({
      addRow: "productStore/addUomConversion",
      deleteRow: "productStore/deleteUomConversion",
    }),
  },
})
export default class FormUomConversion extends Vue {
  formatterNumber = formatterNumber;
  reverseFormatNumber = reverseFormatNumber;

  storeBaseDecimalPlace!: number;
  form!: FormValue;
  addRow!: () => void;
  deleteRow!: (payload: Array<number>) => void;

  selectedRowKeys: Array<number> = [];

  columns = [
    {
      title: this.$t("lbl_uom_source"),
      dataIndex: "uomSource",
      width: "200px",
      scopedSlots: { customRender: "uomSource" },
    },
    {
      title: this.$t("lbl_uom_conversion"),
      dataIndex: "uomConversion",
      width: "200px",
      scopedSlots: { customRender: "uomConversion" },
    },
    {
      title: this.$t("lbl_conversion_rate"),
      dataIndex: "conversionRate",
      width: "200px",
      scopedSlots: { customRender: "conversionRate" },
    },
    {
      title: this.$t("lbl_cogs"),
      dataIndex: "cogs",
      scopedSlots: { customRender: "currency" },
    },
  ];

  onChangeUom(
    e: Option<ProductUomResponseDto> | undefined,
    row: UomConversionRow,
    col: "uomSource" | "uomConversion"
  ): void {
    if (col === "uomConversion") {
      row.uomConversion = e?.label || "";
      row.uomConversionId = e?.value || "";
    } else if (col === "uomSource") {
      row.uomSource = e?.label || "";
      row.uomSourceId = e?.value || "";
    }
  }

  onSelectChange(keys: Array<number>): void {
    this.selectedRowKeys = keys;
  }

  handleDeleteRow(): void {
    this.deleteRow(this.selectedRowKeys);
    this.selectedRowKeys = [];
  }
}
